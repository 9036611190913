.feedback-form {
  background-color: var(--color-text);
  display: flex;
  justify-content: center;

  &__recaptcha {
    margin-bottom: 30px;
  }

  // TODO: fix
  &__errors {
    display: none;

    &:not(:empty) {
      margin-bottom: 30px;
      display: block;
      align-self: flex-start;
    }

    p {
      color: red;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 247px 60px 248px;

  @include max-tablet {
    max-width: 734px;
  }

  @include max-mobile {
    max-width: 360px;
    margin: 25px 116px 30px 117px;
  }
}

.h2-feedback {
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--color-dark-gray);
  margin-bottom: 25px;

  @include max-mobile {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

.p-feedback {
  max-width: 523px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--color-dark-gray);
  margin-bottom: 40px;

  @include max-mobile {
    font-size: 14px;
    margin-bottom: 25px;
  }
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include max-tablet {
    padding: 0px 50px;
  }

}

.input {
  display: flex;
  gap: 30px;
  margin-bottom: 35px;

  @include max-tablet {
    gap: 20px;
    margin-bottom: 40px;
  }

  @include max-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 25px;
  }
}

input {
  width: 457px;
  height: 60px;
  border-radius: 15px;
  border: none;
  box-shadow: var(--box-shadow-input);
  padding: 0 20px 0 20px;

  @include max-tablet {
    width: 320px;
    height: 60px;
  }

  @include max-mobile {
    width: 382px;
    height: 55px;
  }
}

textarea {
  padding-top: 20px;
  padding-left: 20px;
  width: 945px;
  height: 200px;
  border-radius: 15px;
  border: none;
  box-shadow: var(--box-shadow-input);
  margin-bottom: 40px;
  resize: none;

  @include max-tablet {
    width: 661px;
    height: 200px;
    margin-bottom: 50px;
  }

  @include max-mobile {
    width: 382px;
    height: 150px;
    margin-bottom: 25px;
  }
}

input::placeholder,
textarea::placeholder {
  color: var(--color-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form__button {
  width: 168px;
  height: 49px;
  padding: 15px 37px;
  border-radius: 30px;
  border: none;
  background: var(--gradient-main);
  box-shadow: var(--box-shadow-main);
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.button:hover {
  background: linear-gradient(270deg, #24641C 0%, #5B9228 50.31%, #78C134 100%);
}

.button:active {
  background: linear-gradient(270deg, #3FB830 0%, #75bb34 50.31%, #7dd828 100%);
}