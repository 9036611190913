html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  height: 100%;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --color-bg: #1d2235;
  --color-dark-gray: #31374f;
  --color-light-gray: #797979;
  --color-text: #fff;
  --color-text-secondary: #9657ef;
  --gradient-main: linear-gradient(270deg, #36982a 0%, #78be36 50.31%, #91e840 100%);
  --box-shadow-main: 3px 5px 10px 0px #00000040, -3px -5px 10px 0px #00000040;
  --box-shadow-secondary: 0px 4px 4px 0px #00000040;
  --box-shadow-input: 0px 4px 24px 0px #00000040;
}

* {
  box-sizing: border-box;
}

body {
  font-family: Roboto, sans-serif;
}

a {
  text-decoration: none;
}

header {
  background-color: var(--color-dark-gray);
  box-shadow: var(--box-shadow-main);
  position: relative;
}

.container {
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
}

@media (width <= 1439px) {
  .container {
    margin: 0 auto;
    padding: 20px 50px;
  }
}

@media (width <= 833px) {
  .container {
    margin: 0 auto;
    padding: 17px 16px;
  }
}

.logo {
  width: 36px;
  height: 40px;
}

@media (width <= 833px) {
  .logo {
    width: 24px;
    height: 26px;
  }
}

nav {
  width: 100%;
}

.nav-menu {
  justify-content: center;
  align-items: center;
  gap: 106px;
  padding-right: 0;
  display: flex;
}

@media (width <= 1439px) {
  .nav-menu {
    gap: 60px;
  }
}

@media (width <= 833px) {
  .nav-menu {
    display: none;
  }
}

.nav-menu__item a {
  color: var(--color-text);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nav-menu__item a:hover {
  color: #ccc;
}

.nav-menu__item a:active {
  color: #9cdc5f;
}

.language {
  color: var(--color-text-secondary);
  cursor: pointer;
  display: block;
}

@media (width <= 833px) {
  .language {
    display: none;
  }
}

.dropdown {
  background-color: var(--color-dark-gray);
  z-index: 2;
  height: calc(100vh - 60px);
  padding-top: 30px;
  display: none;
  position: absolute;
  inset: 60px 0 0;
  box-shadow: inset 0 5px 10px -5px #00000040;
}

.dropdown__checkbox {
  display: none;
}

.dropdown__checkbox:checked + label + .dropdown {
  display: block;
}

.dropdown__label svg {
  display: none;
}

.dropdown__checkbox:checked + label svg {
  display: block;
}

.dropdown__checkbox:checked + label .dropdown__label__burger {
  display: none;
}

.dropdown__nav {
  flex-direction: column;
  margin-bottom: 90px;
  display: flex;
}

.nav-menu-mobile {
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-bottom: 25px;
  display: flex;
}

.language-mob {
  color: var(--color-text-secondary);
  text-align: center;
}

.social-menu {
  color: var(--color-text);
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: flex;
}

.dropdown__label__burger {
  display: none;
}

@media (width <= 833px) {
  .dropdown__label__burger {
    display: block;
  }
}

.main {
  background-color: var(--color-bg);
  display: flex;
  position: relative;
}

@media (width <= 1439px) {
  .main {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

@media (width <= 833px) {
  .main {
    flex-direction: column;
    height: 810px;
  }
}

.container-main {
  z-index: 1;
  align-items: center;
  gap: 98px;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
}

@media (width <= 1439px) {
  .container-main {
    flex-direction: column;
    align-items: center;
    gap: 104px;
    max-width: 488px;
  }
}

@media (width <= 833px) {
  .container-main {
    flex-direction: column;
    gap: 45px;
  }
}

.main__left {
  margin: 211px 0 323px;
}

@media (width <= 1439px) {
  .main__left {
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin: 65px 139px 0 140px;
  }
}

@media (width <= 833px) {
  .main__left {
    text-align: center;
    flex-direction: column;
    align-items: center;
    max-width: 381px;
    margin: 25px 17px 0 16px;
  }
}

.main__h1 {
  letter-spacing: .15px;
  color: var(--color-text);
  margin-bottom: 40px;
  font-size: 37px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (width <= 1439px) {
  .main__h1 {
    white-space: nowrap;
    text-align: center;
    margin-bottom: 30px;
  }
}

@media (width <= 833px) {
  .main__h1 {
    white-space: wrap;
    margin-bottom: 20px;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.main__paragraph {
  color: var(--color-text);
  margin-bottom: 60px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (width <= 1439px) {
  .main__paragraph {
    margin-bottom: 44px;
  }
}

@media (width <= 833px) {
  .main__paragraph {
    margin-bottom: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.main__right {
  margin: 68px 0 50px;
}

@media (width <= 1439px) {
  .main__right {
    margin: 0;
  }
}

@media (width <= 833px) {
  .main__right {
    margin: 0;
  }
}

.main__button {
  background: var(--gradient-main);
  box-shadow: var(--box-shadow-main);
  color: var(--color-text);
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 263px;
  padding: 15px 37px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.main__button .main__button:hover {
  box-shadow: var(--box-shadow-main);
}

.main__button .main__button:active {
  box-shadow: none;
}

@media (width <= 833px) {
  .main__button {
    text-align: center;
    width: 181px;
    padding: 15px 37px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (width <= 1439px) {
  .phones {
    width: 489px;
    height: 626px;
    margin-bottom: 105px;
  }
}

@media (width <= 833px) {
  .phones {
    width: 382px;
    height: 489px;
  }
}

.oval {
  background: radial-gradient(50% 50%, #ffffff14 0%, #fff0 100%);
  border-radius: 995px;
  width: 69%;
  height: 79%;
  position: absolute;
}

@media (width <= 1439px) {
  .oval {
    width: 100%;
    height: 40%;
  }
}

@media (width <= 833px) {
  .oval {
    border-radius: 414px;
    width: 100%;
    height: 43%;
  }
}

.options {
  background-color: var(--color-text);
}

.options-container {
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
}

@media (width <= 1439px) {
  .options-container {
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: 610px;
    margin: 0 auto;
    padding: 0;
    display: flex;
  }
}

@media (width <= 833px) {
  .options-container {
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    display: flex;
  }
}

.cards-left {
  flex-direction: column;
  gap: 30px;
  margin: 45px 0 53px;
  display: flex;
}

@media (width <= 1439px) {
  .cards-left {
    order: -1;
    gap: 44px;
    width: 50%;
    margin: 65px 0 60px;
    padding-right: 42.5px;
  }
}

@media (width <= 833px) {
  .cards-left {
    gap: 25px;
    margin: 0;
    padding: 0;
  }
}

.card-left {
  text-align: right;
  width: 261px;
}

@media (width <= 1439px) {
  .card-left {
    width: initial;
    text-align: center;
  }
}

@media (width <= 833px) {
  .card-left {
    text-align: center;
  }
}

.name-card {
  color: var(--color-text-secondary);
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (width <= 833px) {
  .name-card {
    margin: 15px 0 10px;
    font-size: 18px;
  }
}

.description-card {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (width <= 833px) {
  .description-card {
    font-size: 14px;
  }
}

.card-icon {
  box-shadow: var(--box-shadow-secondary);
  border-radius: 8px;
  width: 60px;
  height: 60px;
}

@media (width <= 833px) {
  .card-icon {
    width: 42px;
    height: 42px;
  }
}

.screen {
  margin: 0 98px 0 97px;
}

@media (width <= 1439px) {
  .screen {
    object-fit: contain;
    order: 1;
    align-items: center;
    width: 100%;
    height: 603px;
    margin-bottom: 65px;
  }
}

@media (width <= 833px) {
  .screen {
    order: 1;
    width: 221px;
    height: 443px;
  }
}

.cards-right {
  flex-direction: column;
  gap: 30px;
  margin: 45px 0 46px;
  display: flex;
}

@media (width <= 1439px) {
  .cards-right {
    order: 0;
    gap: 37px;
    width: 50%;
    margin: 65px 0 60px;
    padding-left: 42.5px;
  }
}

@media (width <= 833px) {
  .cards-right {
    gap: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 0;
  }
}

.card-right {
  text-align: left;
  width: 261px;
}

@media (width <= 1439px) {
  .card-right {
    text-align: center;
    width: initial;
  }
}

@media (width <= 833px) {
  .card-right {
    text-align: center;
  }
}

.download {
  background-color: var(--color-bg);
  justify-content: center;
  display: flex;
}

@media (width <= 833px) {
  .download {
    height: 765px;
  }
}

.download-container {
  flex-direction: column;
  align-items: center;
  margin: 50px 248px 60px;
  display: flex;
}

@media (width <= 1439px) {
  .download-container {
    margin: 65px 50px 70px;
  }
}

@media (width <= 833px) {
  .download-container {
    margin: 30px 66px;
  }
}

.h2 {
  text-align: center;
  color: var(--color-text);
  margin-bottom: 45px;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (width <= 1439px) {
  .h2 {
    margin-bottom: 60px;
  }
}

@media (width <= 833px) {
  .h2 {
    white-space: nowrap;
    margin-bottom: 25px;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.stores {
  flex-direction: row;
  gap: 134px;
  display: flex;
}

@media (width <= 1439px) {
  .stores {
    justify-content: center;
    gap: 40px;
    padding: 0 50px;
  }
}

@media (width <= 833px) {
  .stores {
    flex-direction: column;
    gap: 25px;
  }
}

.googlepay {
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.name-store {
  color: var(--color-text);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.coming-soon {
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appstore {
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.name-store-1 {
  color: var(--color-text);
  margin: 20px 0 15px;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.getapps, .rustore {
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (width <= 833px) {
  .rustore {
    margin-bottom: 30px;
  }
}

.feedback-form {
  background-color: var(--color-text);
  justify-content: center;
  display: flex;
}

.feedback-form__recaptcha {
  margin-bottom: 30px;
}

.feedback-form__errors {
  display: none;
}

.feedback-form__errors:not(:empty) {
  align-self: flex-start;
  margin-bottom: 30px;
  display: block;
}

.feedback-form__errors p {
  color: red;
}

.feedback-form__errors p:not(:last-child) {
  margin-bottom: 10px;
}

.feedback-container {
  flex-direction: column;
  align-items: center;
  margin: 50px 247px 60px 248px;
  display: flex;
}

@media (width <= 1439px) {
  .feedback-container {
    max-width: 734px;
  }
}

@media (width <= 833px) {
  .feedback-container {
    max-width: 360px;
    margin: 25px 116px 30px 117px;
  }
}

.h2-feedback {
  text-align: center;
  color: var(--color-dark-gray);
  margin-bottom: 25px;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (width <= 833px) {
  .h2-feedback {
    margin-bottom: 20px;
    font-size: 28px;
  }
}

.p-feedback {
  text-align: center;
  color: var(--color-dark-gray);
  max-width: 523px;
  margin-bottom: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (width <= 833px) {
  .p-feedback {
    margin-bottom: 25px;
    font-size: 14px;
  }
}

form {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (width <= 1439px) {
  form {
    padding: 0 50px;
  }
}

.input {
  gap: 30px;
  margin-bottom: 35px;
  display: flex;
}

@media (width <= 1439px) {
  .input {
    gap: 20px;
    margin-bottom: 40px;
  }
}

@media (width <= 833px) {
  .input {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 25px;
    display: flex;
  }
}

input {
  box-shadow: var(--box-shadow-input);
  border: none;
  border-radius: 15px;
  width: 457px;
  height: 60px;
  padding: 0 20px;
}

@media (width <= 1439px) {
  input {
    width: 320px;
    height: 60px;
  }
}

@media (width <= 833px) {
  input {
    width: 382px;
    height: 55px;
  }
}

textarea {
  box-shadow: var(--box-shadow-input);
  resize: none;
  border: none;
  border-radius: 15px;
  width: 945px;
  height: 200px;
  margin-bottom: 40px;
  padding-top: 20px;
  padding-left: 20px;
}

@media (width <= 1439px) {
  textarea {
    width: 661px;
    height: 200px;
    margin-bottom: 50px;
  }
}

@media (width <= 833px) {
  textarea {
    width: 382px;
    height: 150px;
    margin-bottom: 25px;
  }
}

input::placeholder, textarea::placeholder {
  color: var(--color-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form__button {
  background: var(--gradient-main);
  box-shadow: var(--box-shadow-main);
  color: var(--color-text);
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  width: 168px;
  height: 49px;
  padding: 15px 37px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button:hover {
  background: linear-gradient(270deg, #24641c 0%, #5b9228 50.31%, #78c134 100%);
}

.button:active {
  background: linear-gradient(270deg, #3fb830 0%, #75bb34 50.31%, #7dd828 100%);
}

footer {
  background-color: var(--color-dark-gray);
  box-shadow: var(--box-shadow-main);
}

.container-footer {
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  padding: 27px 0 25px;
  display: flex;
}

.menu-footer {
  justify-content: center;
  align-items: flex-start;
  gap: 168px;
  padding-right: 0;
  display: flex;
}

@media (width <= 1439px) {
  .menu-footer {
    gap: 45px;
    margin: 0 auto;
    padding: 20px 50px;
  }
}

@media (width <= 833px) {
  .menu-footer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
  }
}

.documents {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.document-item a {
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.links {
  gap: 162px;
  display: flex;
}

.links a {
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.social {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.social a {
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.document-item a:hover, .links a:hover, .social a:hover {
  color: #ccc;
}

.logo-2 {
  width: 36px;
  height: 40px;
}

@media (width <= 1439px) {
  .links {
    gap: 60px;
    display: flex;
  }

  .logo-2 {
    display: none;
  }

  .forum-link {
    white-space: nowrap;
  }
}

@media (width <= 833px) {
  .logo-2 {
    display: none;
  }

  .links {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .social, .documents {
    align-items: center;
    gap: 20px;
  }
}

.button-up {
  cursor: pointer;
  box-shadow: var(--box-shadow-secondary);
  background: center no-repeat;
  border: none;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  display: none;
  position: fixed;
  bottom: 120px;
  right: 85px;
}

.button-up:hover {
  box-shadow: var(--box-shadow-main);
}

.button-up:active {
  box-shadow: none;
}

@media (width <= 833px) {
  .button-up {
    width: 52px;
    height: 52px;
    bottom: 240px;
    right: 16px;
  }
}

.modal-container {
  background: var(--color-dark-gray);
  box-shadow: var(--box-shadow-main);
  z-index: 1;
  border-radius: 20px;
  width: 357px;
  height: 123px;
  margin: 0 auto;
  animation: .3s linear forwards upToDown;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  transform: translateY(30px);
}

@media (width <= 833px) {
  .modal-container {
    width: 327px;
    height: 98px;
  }
}

@keyframes upToDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(30px);
  }
}

.done {
  gap: 10px;
  margin: 30px 231px 10px 30px;
  display: flex;
}

@media (width <= 833px) {
  .done {
    margin: 22px 209px 10px 22px;
  }
}

.h1_modal {
  text-transform: uppercase;
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.modal-text {
  color: var(--color-text);
  margin-left: 64px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

@media (width <= 833px) {
  .modal-text {
    margin-left: 56px;
  }
}

/*# sourceMappingURL=index.04a205ee.css.map */
