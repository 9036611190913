.options {
    background-color: var(--color-text);
  }
  
  .options-container {
    max-width: 1140px;
    display: flex;
    justify-content: space-between;
    padding: 50px 0px;
    margin: 0 auto;

    @include max-tablet {
      display: flex;
      flex-wrap: wrap;
      max-width: 610px;
      justify-content: flex-end;
      padding: 0px;
      margin: 0 auto;
    }

    @include max-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0px;
    }
  }
  
  .cards-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 45px 0 53px 0;
    // width: 263px;

    @include max-tablet {
      padding-right: calc(85px / 2);
      order: -1;
      width: 50%;
      margin: 65px 0px 60px 0px;
      gap: 44px;
    }

    @include max-mobile {
      padding: 0px;
      gap: 25px;
      margin: 0;
    }
  }
  
  .card-left {
    text-align: right;
    width: 261px;

    @include max-tablet {
      
      width: initial;
      text-align: center;
    }

    @include max-mobile {
      text-align: center;
    }
  }
  
  .name-card {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--color-text-secondary);
    margin-top: 20px;
    margin-bottom: 15px;

    @include max-mobile {
      font-size: 18px;
      margin: 15px 0 10px 0;
    }
  }
  
  .description-card {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include max-tablet {
      // min-height: 68px;
    }

    @include max-mobile {
      font-size: 14px;
    }
  }
  
  .card-icon {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    box-shadow: var(--box-shadow-secondary);

    @include max-mobile {
      width: 42px;
      height: 42px;
    }
  }
  
  .screen {
    margin: 0 98px 0 97px;

    @include max-tablet {
      width: 100%;
      object-fit: contain;
      height: 603px;
      margin-bottom: 65px;
      align-items: center;
    }

    @include max-tablet {
      order: 1;
    }

    @include max-mobile {
      order: 1;
      width: 221px;
      height: 443px;
    }
  }
  
  .cards-right {
    display: flex;
    flex-direction: column;
    margin: 45px 0 46px 0;
    gap: 30px;
    // width: 262px;

    @include max-tablet {
      padding-left: calc(85px / 2);
      order: 0;
      width: 50%;
      margin: 65px 0px 60px 0px;
      gap: 37px;
    }

    @include max-mobile {
      margin-top: 25px;
      padding: 0px;
      gap: 25px;
      margin-bottom: 25px;
    }
  }
  
  .card-right {
    text-align: left;
    width: 261px;

    @include max-tablet {
      text-align: center;
      width: initial;
    }

    @include max-mobile {
      text-align: center;
    }
  }