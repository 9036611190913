.main {
    background-color: var(--color-bg);
    display: flex;
    position: relative;

    @include max-tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include max-mobile {
      height: 810px;
      flex-direction: column;
    }
    /* align-items: center; */
  }

  .container-main {
    max-width: 1140px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    display: flex;
    gap: 98px;
    z-index: 1;

    @include max-tablet {
      flex-direction: column;
      max-width: 488px;
      align-items: center;
      gap: 104px;
    }

    @include max-mobile {
      flex-direction: column;
      gap: 45px;
    }
  }
  .main__left {
    margin: 211px 0px 323px 0px;

    @include max-tablet {
      // max-width: 555px;
      margin: 65px 139px 0px 140px;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    @include max-mobile {
      max-width: 381px;
      margin: 25px 17px 0px 16px;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  
  .main__h1 {
    font-size: 37px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.15px;
    color: var(--color-text);
    margin-bottom: 40px;

    @include max-tablet {
      white-space: nowrap;
      margin-bottom: 30px;
      text-align: center;
    }

    @include max-mobile {
      white-space: wrap;
      margin-bottom: 20px;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  
  .main__paragraph {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: var(--color-text);
    margin-bottom: 60px;

    @include max-tablet {
      margin-bottom: 44px;
    }

    @include max-mobile {
      margin-bottom: 40px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  
  .main__right {
    margin: 68px 0px 50px 0px;

    @include max-tablet {
      margin: 0px;
    }

    @include max-mobile {
      margin: 0px;
    }
  }
  
  .main__button {
    width: 263px;
    padding: 15px 37px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: var(--gradient-main);
    box-shadow: var(--box-shadow-main);
    color: var(--color-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;

    .main__button:hover {
      box-shadow: var(--box-shadow-main);
    }
    
    .main__button:active {
      box-shadow: none;
    }

    @include max-mobile {
      width: 181px;
      padding: 15px 37px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }
  }
  
  @include max-tablet {
    .phones {
      width: 489px;
      height: 626px;
      margin-bottom: 105px;
    }
  }

  @include max-mobile {
    .phones {
        width: 382px;
        height: 489px;
  }
  }
  .oval {
    position: absolute;
    width: 69%;
    height: 79%;
    border-radius: 995px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 100%);
  
    @include max-tablet {
      width: 100%;
      height: 40%;
    }

    @include max-mobile {
      width: 100%;
      height: 43%;
      border-radius: 414px;
    }
  }