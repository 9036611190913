.download {
    background-color: var(--color-bg);
    display: flex;
    justify-content: center;
    // height: 340px;

    @include max-mobile {
      height: 765px;
    }
  }
  .download-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 248px 60px 248px;

    @include max-tablet {
      margin: 65px 50px 70px 50px;
    }

    @include max-mobile {
      margin: 30px 66px;
    }
  }
  .h2 {
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--color-text);
    margin-bottom: 45px;

    @include max-tablet {
    margin-bottom: 60px;
    }

    @include max-mobile {
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 25px;
      white-space: nowrap;
    }
  }
  .stores {
    display: flex;
    flex-direction: row;
    gap: 134px;

    @include max-tablet {
      padding: 0px 50px;
      justify-content: center;
      gap: 40px;
    }

    @include max-mobile {
      flex-direction: column;
      gap: 25px;
    }
  }
  .googlepay {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    gap: 20px;
  }
  .name-store {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--color-text);
  }
  .coming-soon {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--color-text);
  }
  .appstore {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
  }
  .name-store-1 {
    margin: 20px 0px 15px 0px;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--color-text);
  }
  .getapps {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
  }
  .rustore {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;

    @include max-mobile {
      margin-bottom: 30px;
    }
  }