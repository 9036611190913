:root {
    --color-bg: #1D2235;
    --color-dark-gray: #31374F;
    --color-light-gray: #797979;
    --color-text: #FFFFFF;
    --color-text-secondary: #9657EF;

    --gradient-main: linear-gradient(270deg, #36982A 0%, #78BE36 50.31%, #91E840 100%);


    --box-shadow-main: 3px 5px 10px 0px rgba(0, 0, 0, 0.25), -3px -5px 10px 0px rgba(0, 0, 0, 0.25);

    --box-shadow-secondary: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    --box-shadow-input: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    

}
*{
    box-sizing: border-box;
}

body {
    
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
}
