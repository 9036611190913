header {
  background-color: var(--color-dark-gray);
  box-shadow: var(--box-shadow-main);
  position: relative;
}

.container {
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  margin: 0 auto;

  // @media (max-width: 768px) {
  //   padding: 20px 50px;
  //   margin: 0 auto;
  // }

  @include max-tablet {
    padding: 20px 50px;
    margin: 0 auto;
  }

  // @media (max-width: 480px) {
  //   padding: 17px 16px;
  //   margin: 0 auto;
  // }

  @include max-mobile {
    padding: 17px 16px;
    margin: 0 auto;
  }
}

.logo {
  width: 36px;
  height: 40px;

  @include max-mobile {
    width: 24px;
    height: 26px;
  }
}
nav {
  width: 100%;
}
.nav-menu {
  display: flex;
  padding-right: 0px;
  justify-content: center;
  align-items: center;
  gap: 106px;
  // margin-left: 303px;
  // margin-right: 286px;

  @include max-tablet {
    gap: 60px;
  }

  @include max-mobile {
   display: none; 
  }
}

.nav-menu__item a {
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.nav-menu__item a:hover {
  color: #cccccc;
}

.nav-menu__item a:active {
  color: #9cdc5f;
}

.language {
  color: var(--color-text-secondary);
  cursor: pointer;
  display: block;

  @include max-mobile {
    display: none;
  }
}

.dropdown {
  display: none;
  position: absolute;
  height: calc(100vh - 60px);
  top: 60px;
  padding-top: 30px;
  background-color: var(--color-dark-gray);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.25) inset;
}
.dropdown__checkbox {
  display: none;
}
.dropdown__checkbox:checked+label+.dropdown {
  display: block;
}
.dropdown__label svg {
  display: none;
}
.dropdown__checkbox:checked+label svg {
  display: block;
}
.dropdown__checkbox:checked+label .dropdown__label__burger {
  display: none;
}
.dropdown__nav {
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
}

.nav-menu-mobile {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;
  align-items: center;
}

.language-mob {
  color: var(--color-text-secondary);
  text-align: center;
}

.social-menu {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  color: var(--color-text);
}

.dropdown__label__burger {
  display: none;
  @include max-mobile {
  display: block;
  }
}

