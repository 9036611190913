.modal-container {
  display: none;
  width: 357px;
  height: 123px;
  // bottom: 50px;
  transform: translateY(30px);
  animation: upToDown 300ms linear forwards;
  border-radius: 20px;
  background: var(--color-dark-gray);
  box-shadow: var(--box-shadow-main);
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;

  @include max-mobile {
    width: 327px;
    height: 98px;
  }

  &.animate {
    @keyframes upToDown {
      0% {
        transform: translateY(0px);
      }

      100% {
        transform: translateY(30px);
      }
    }
  }
}

.done {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  margin-right: 231px;
  margin-bottom: 10px;
  margin-left: 30px;

  @include max-mobile {
    margin: 22px 209px 10px 22px;
  }
}

.h1_modal {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-text);
}

.modal-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: var(--color-text);
  margin-left: 64px;

  @include max-mobile {
    margin-left: 56px;
  }
}