footer {
  background-color: var(--color-dark-gray);
  box-shadow: var(--box-shadow-main);
}

.container-footer {
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 0 25px 0;
  margin: 0 auto;
}

.menu-footer {
  display: flex;
  padding-right: 0px;
  // justify-content: flex-end;
  // gap: 106px;
  justify-content: center;
  gap: 168px;
  align-items: flex-start;

  @include max-tablet {
    padding: 20px 50px;
    gap: 45px;
    margin: 0 auto;
  }

  @include max-mobile {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
  }
}

.documents {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.document-item a {
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.links {
  display: flex;
  gap: 162px;
}

.links a {
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.social {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social a {
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.document-item a:hover {
  color: #cccccc;
}

.links a:hover {
  color: #cccccc;
}

.social a:hover {
  color: #cccccc;
}

.logo-2 {
  width: 36px;
  height: 40px;
}

@include max-tablet {
  .links {
    display: flex;
    gap: 60px;
  }

  .logo-2 {
    display: none;
  }

  .forum-link {
    white-space: nowrap;
  }
}

@include max-mobile {
  .logo-2 {
    display: none;
  }


  .links {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .social {
    gap: 20px;
    align-items: center;
  }

  .documents {
    gap: 20px;
    align-items: center;
  }
}

.button-up {
  position: fixed;
  right: 85px;
  bottom: 120px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  background: transparent no-repeat center center;
  border: none;
  border-radius: 30px;
  box-shadow: var(--box-shadow-secondary);
}

.button-up:hover {
  box-shadow: var(--box-shadow-main);
}

.button-up:active {
  box-shadow: none;
}

@include max-mobile {
  .button-up {
    width: 52px;
    height: 52px;
    right: 16px;
    bottom: 240px;
  }
}