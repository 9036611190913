@mixin max-mobile {
  // указываем таблет 768 минус 1 = 767, тк мобайл идёт до начала планшетной
    @media (max-width: 833px) {
      @content;
    }
  }

@mixin max-tablet {
    @media (max-width: 1439px) {
      @content;
    }
  }